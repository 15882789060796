#FAQ .header {
    width: 40%;
    text-align: start;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 30px;
    box-sizing: border-box;
}

#FAQ a, #FAQ a:hover {
    color: #dbf059;
}

#FAQ {
    display: flex;
    overflow-x: hidden;
}

#FAQ .container {
    text-align: start;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 60%;
    /* overflow: auto; */
}

#FAQ .questions {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 70%;
    height: 60%;
    overflow-y: scroll;
    padding: 10px;
    /* margin-top: 100px; */
}

#FAQ .question {
    margin: 20px 0px 20px 0px;
}

#FAQ .question:hover {
    font-weight: bolder;
    margin: 20px 0px 20px 0px;
    cursor: pointer;
}

#FAQ .answer {
    padding: 20px;
    opacity: 60%;
}

@media screen and (max-width:425px) {
    #FAQ {
        flex-direction: column;
        margin-top: 250px;
    }
    #FAQ .header {
        width: 100%;
        text-align: start;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 30px;
    }
    #FAQ .container {
        width: 100%;
        /* border: 1px solid white; */
    }
    #FAQ .questions {
        width: 80%;
        padding: 30px 0px 100px 0px;
    }
}

@media screen and (max-width:375px) {
    #FAQ .header {
        width: 90%;
    }
}
