#FullService .header {
    text-align: start;
    margin: 20vh 60px 20vh 60px;
    width: 30%;
    height: 70vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

#FullService .services {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    text-align: start;
    margin-top: 10vh;
}

#FullService .description, #FullService .included-in {
    padding: 30px;
}

#FullService .included-in {
    display: flex;
}

#FullService .services .card {
    border-bottom: rgba(255,255,255,0.7) 1px solid;
}

#FullService .services {
    height: 90vh;
    overflow: auto;
}

#FullService h3:hover {
    color:#2479bc;
    cursor: pointer;
}

#FullService {
    display: flex;
}

@media screen and (max-width:425px) {
    #FullService {
        display: block;
    }

    #FullService .header {
        text-align: start;
        margin: 40vh 42.5px 20px 42.5px;
        width: 80%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    #FullService .services {
        width: 80%;
        margin-left: auto;
        margin-right: auto;
        text-align: start;
        margin-top: 0vh;
    }
}
