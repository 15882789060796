.slide {
    height: 50%;
    margin-top: 100px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

#ImageSlider {
    display: flex;
    align-items: center;
    height: 100%;
}

/* #ImageSlider img {
    height: 100%;
} */

.backward-btn, .forward-btn {
    cursor: pointer;
    height: 100px;
    width: 100px;
}

@media screen and (max-width:768px) {
    #ImageSlider {
        display: flex;
        align-items: center;
        height: 100%;
    }
}

@media screen and (max-width:768px) {
    .backward-btn, .forward-btn {
        height: 50px;
        width: 50px;
        margin-top: 80px;
    }
}
