#Landing {
    max-width: 150vw;
    height: 100%;
}
#Landing video {
    width: 100%;
    height: 100vh;
    object-fit: cover;
    overflow: hidden;
    /* trying to get rid of the video overflow on mobile */
}

#Landing .container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transition: all .2s ease-in-out;
    width:100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(0,0,0,.7);
}

.tagline {
    font-size: 100px;
}

#Landing .buttons {
    display: flex;
}

@media screen and (max-width:425px) {
    #Landing video {
        height: 90vh;
    }
    #Landing .container {
        height: 92vh;
    }

    .tagline {
        font-size: 4em;
        width: 80%;
    }

    #Process {
        margin: 200px 0px 200px 0px;
    }
    #Landing .buttons {
        display: flex;
        flex-direction: column;
        width: 80%;
        align-items: center;
    }
    #Landing button {
        width: 50vw;
        box-sizing: border-box;
    }
}
