#Reviews {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#Reviews a {
    color:#fefeff;}

#Reviews .reviews .review-container {
    border: 1px solid #3c4043;
    border-radius: 20px;
    margin: 20px;
    padding: 20px;
    width: 30vh;
    height: 18vh;
    display: flex;
}

#Reviews .reviews .review-container a {
    display: flex;
}

#Reviews .reviews .review-container .user-pfp {
    width: 50px;
    height: 50px;
}

#Reviews .user-pfp, #Reviews .right-container .name, #Reviews .right-container .stars {
    margin-bottom: 10px;
}

#Reviews .review-container .publishedAtDate {
    font-size: 14px;
    margin-left: 10px;
    opacity: 50%;
}

#Reviews .reviews .review-container .right-container {
    text-align: start;
    align-items: flex-start;
    margin-left: 10px;
    width: 100%;
    justify-content: flex-start;
}

#Reviews .reviews .review-container .right-container .stars-date{
    display: flex;
}

#Reviews .reviews {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 60%;
}

#Reviews .review-image {
    width: 100%;
}

#Reviews .avg-rating .star {
    width: 36px;
    height: 36px;
}

#Reviews .avg-rating img {
    width: 10%;
    margin-right: 10px;
}

#Reviews .avg-rating {
    display: flex;
    align-items: center;
    justify-content: center;
}

#Reviews .buttons {
    margin-top: 20px;
    display: flex;
}

@media screen and (max-width:1470px) {
    #Reviews {
        height: 100%;
    }

    #Reviews .reviews {
        width: 100%;
        box-sizing: border-box;
    }

    #Reviews .reviews .review-container {
        width: 40vh;
        height: 30vh;
    }
    #Reviews .avg-rating .star {
        width: 24px;
        height: 24px;
    }
}

@media screen and (max-width:768px) {
    #Reviews .reviews .review-container {
        width: 50vh;
        height: 100%;
    }
    #Reviews .reviews {
        font-size: 14px;
    }
}

@media screen and (max-width:425px) {
    #Reviews .buttons {
        width: 100%;
        flex-direction: column;
        align-items: center;
    }

    #Reviews ul {
        padding-inline-start: 0px;
    }
    #Reviews .avg-rating {
        flex-direction: column;
    }
    #Reviews .avg-rating img {
        width: 20%;
    }
}

@media screen and (max-width:375px) {
    #Reviews .reviews .review-container .right-container .stars-date{
        flex-direction: column;
    }
    #Reviews .right-container .stars {
        margin-bottom: 0px;
    }
    #Reviews .review-container .publishedAtDate {
        margin-left: 0px;
        margin-bottom: 10px;
    }
}
