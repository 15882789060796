#AboutUs {
    margin-bottom: 90px;
    display: flex;
    align-items: center;
}

#AboutUs .text {
    width: 50%;
    width: 50%;
    text-align: start;
    height: 600px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 30px;
}

.washing {
    height: 100%;
    width: 50%;
}

h1 {
    font-size: 70px;
    margin-top: 0;
}

#AboutUs button {
    margin: 50px 0px 10px 0px;
}

@media screen and (max-width:768px) {
    h1 {
        font-size: 42px;
        margin-top: 0;
    }
}

@media screen and (max-width:425px) {
    #AboutUs {
        flex-direction: column;
        padding: 20px;
    }
    #AboutUs .text {
        width: 100%;
        text-align: start;
        height: 600px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .washing {
        margin-top: 50px;
        height: 200%;
        width: 100%;
    }

    #AboutUs .description {
        box-sizing: border-box;
    }
}
