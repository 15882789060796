#Contact {
    /* border: 1px solid white; */
    height: 100vh;
    width: 100%;
    display: flex;
}

#Contact h1 {
    font-size: 90px;
    margin-bottom: 0px;
}

/* #Contact .left-container, #Contact .right-container {
    border: 1px solid red;
} */

#Contact .left-container {
    width: auto;
}

#Contact .right-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

#Contact .text {
    text-align: start;
    /* margin-bottom: 0px; */
}

#Contact .left-container img {
    height: 100%;
}

#Contact form{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 600px;
    text-align: start;
    margin: 20px;
}

#Contact .success, #Contact .fail {
    height: 60%;
    font-size: 30px;
    width: 600px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

#Contact .row {
    display: flex;
    width: 600px;
}

#Contact .row .input-column {
    display: flex;
    flex-direction: column;
    width:90%;
    padding: 20px;
}

#Contact .input-column {
    display: flex;
    flex-direction: column;
    width:93%;
    padding: 20px;
}

#Contact label {
    margin-bottom: 10px;
}

#Contact input, #Contact textarea {
    height: 30px;
    padding: 5px;
}

#Contact textarea {
    height: 200px;
}

#Contact a, #Contact a:visited {
    color: #dbf059;
}

@media screen and (max-width:768px) {
    #Contact {
        /* border: 1px solid white; */
        height: 100vh;
        width: 100vw;
        display: flex;
        margin-top: -146px;
    }
    #Contact .text {
        text-align: start;
        margin-top: 100px;
        margin-left: 40px;
    }
    #Contact h1 {
        font-size: 54px;
        margin-bottom: 0px;
    }
    #Contact .left-container {
        width: 50%;
    }
    #Contact .description {
        width: 90%;
    }
    #Contact .row {
        display: flex;
        flex-direction: column;
        width: 350px;
    }
    #Contact .row .input-column {
        display: flex;
        width:90%;
        padding: 10px 20px 0px 20px;
    }
    #Contact form{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        width: 350px;
        text-align: start;
        margin: 0px;
    }
    #Contact .right-container {
        overflow: scroll;

    }
    #Contact .success, #Contact .fail {
        height: 60%;
        font-size: 24px;
        width: 50%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

}
@media screen and (max-width:425px) {
    #Contact {
        margin: 40vh 0 15vh 0;
    }
    #Contact .left-container {
        display: none;
    }
    #Contact form{
        margin: 20px;
    }
    #Contact button {
        margin-left: 20px;
        width: 75vw;
        font-size: .7rem;
    }
    #Contact .right-container {
        overflow: visible;
    }
}
