body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: #0e232d; */
  background-color: #131314;
  color: #fefeff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

button{
  border-radius: 50px;
  padding: 20px;
  margin: 10px;
  width: 200px;
  background-color: rgba(255, 255, 255, 0.15);
  box-shadow: 0 8px 32px 0 rgba(107, 107, 107, 0.37);
  backdrop-filter: blur(6px);
  -webkit-backdrop-filter: blur(6px);
  border: 1px solid rgba(255, 255, 255, 0.18);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
  transition: transform 200ms ease;
  overflow: hidden;
  color: white;
}

button:before {
  content: "";
  position: absolute;
  height: 180%;
  width: 50px;
  transform: rotate(30deg);
  background-color: rgba(255, 255, 255, 0.2);
  left: -100px;
}

button:hover {
  background-color: rgba(255, 255, 255, 0.15);
  color:#fefeff;
  cursor: pointer;
  transform: scale(1.1);
}

button:hover:before {
  transition: left 400ms ease;
  left: 210px;
  cursor: pointer;
}

#Portfolio #ImageSlider button{
  background-color: none;
  box-shadow: none;
  backdrop-filter: none;
  -webkit-backdrop-filter: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
  transition: none;
  overflow: hidden;
}

#Portfolio #ImageSlider button:hover{
  background-color: none;
  color:none;
  cursor: pointer;
  transform: none;
}

#Portfolio #ImageSlider button:before {
  display: none;
}

#Portfolio #ImageSlider button:hover:before {
  transition: none;
  left: none;
  cursor: none;
}

#AddOns .arrows .previous, #AddOns .arrows .next, #SelectPackage .arrow .next,
#ScheduleAppointment .arrows .previous  {
  border-radius: 0px;
  background-color: transparent;
  box-shadow: none;
  backdrop-filter: none;
  -webkit-backdrop-filter: none;
  border: 0px solid white;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
  transition: none;
  overflow: hidden;
  color: white;
  font-size: 1rem ;
  width: 300px;
}

#SelectPackage .arrow {
  display: flex;
  justify-content: flex-end;
}

#AddOns .arrows .previous:before, #AddOns .arrows .next:before, #SelectPackage .arrow .next:before, #ScheduleAppointment .arrows .previous:before  {
  content: "";
  position: absolute;
  height: 180%;
  width: 50px;
  transform: none;
  background-color: rgba(255, 255, 255, 0.2);
  left: -100px;
}

#Maps .placeholder {
  width: 100%;
  height: 600px;
  background-color: #1A4254;
  display: flex;
  justify-content: center;
  align-items: center;
}

a:visited, a {
  text-decoration: none;
  color: black;
}

#Nav a:visited, #Nav a {
  color: #fefeff;
}

a:hover {
  color:#fefeff;
}

li {
  list-style-type: none;
}

em, #Process a, #Process a:hover, #Process a:visited {
  color: #dbf059;
}
#SinglePage {
  width: 100vw;
  box-sizing: border-box;
  overflow-x: hidden;
}

#Process {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-direction: column;
  width: 98vw;
}

#Process .container {
  width: 100%;
  height: 30%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 60px;
}

#Process .container .sub {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 20%;
}

#Process .container .description {
  width: 100%;
}

#Why {
  height: 80vh;
  margin-bottom: 90px;
  background-image: url('./assets/DSC02962.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  width: 98vw;
  background-position: center;
  background-attachment: fixed;
  box-shadow: inset 0 0 0 1000px rgba(0,0,0,.7);
  display: flex;
}

#Why .header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 40%;
  text-align: start;
  align-items: flex-start;
  padding: 20px;
}

#Why .container {
  width: 60%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

#Why ul {
  width: 60%;
  margin-bottom: 30px;
}

#Why li h3{
  color: #dbf059;
}

@media screen and (max-width:1024px) {
  h1 {
    font-size: 80px;
  }
}

@media screen and (max-width:768px) {
  h1, title {
    font-size: 60px;
  }
  button {
    border-radius: 50px;
    padding: 20px;
    border: 0px;
    margin: 10px;
    width: 100px;
    font-size: 8px;
  }
}

@media screen and (max-width:425px) {
  #SinglePage {
    margin-top: 150px;
  }

  #Process {
    margin-top: 100px;
  }

  #Process .faq {
    padding: 0px 30px 0px 30px;
  }
  #Process .container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    margin-bottom: 60px;
  }

  #Process .container .sub {
    width: 90%;
  }
  #Why {
    flex-direction: column;
    width: 100vw;
    height: 100vh;
    background-image: url('./assets/7F0A06B5-35FE-4BDA-B54A-27539D773FFF_1_201_a.jpeg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
  }
  #Why .header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    text-align: center;
    align-items: center;
    padding: 20px;
    padding: 20px 0px 0px 0px;
  }

  #Why .header h1 {
    font-size: 42px;
    width: 90%;
    margin-bottom: 0px;
  }

  #Why .container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  #Why ul {
    width: 70%;
    margin-bottom: 30px;
    padding-left: 0px;;
  }

  #Why li h3{
    color: #dbf059;
  }
  .call-btn {
    border-radius: 100%;
    border: 1px;
    background-color: #dbf059;
    padding: 12px;
    color: #131314;
    position: fixed;
    bottom: 30px;
    right: 80px;
  }

  .call-btn {
    bottom: 10px;
    right: 10px;
  }
  #SinglePage button {
    width: 70vw;
    font-size: .7rem;
  }

}

@media screen and (max-width:375px) {
  #SinglePage button {
    width: 70vw;
    font-size: .7rem;
  }
}

@media screen and (max-width:375px) {
  #Why {
    padding-bottom: 30px;
  }
}
