.Schedule {
    height: 100%;
    /* margin-top: 60px; */
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-bottom: 90px;
}

#SelectPackage, #AddOns, #ScheduleAppointment {
    display: flex;
    width: 80%;
    flex-direction: column;
    justify-content: center;
    margin-top: 15vh;
}

.tracker {
    border-radius: 100%;
    background-color: #2479bc;
    padding: 30px;
    margin-right: 30px;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Schedule .container, #AddOns .container, #ScheduleAppointment .container {
    display: flex;
    align-items: center;
    padding: 30px;
}

.Schedule .container {
    /* border: 1px solid white; */
    justify-content: space-between;
}

.Schedule .container .sub {
    display: flex;
}

.packages {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.top-packages {
    display: flex;
    justify-content: center;
}

.package {
    width: 25%;
    margin: 20px;
    background-color: #1691de;
    border-radius: 20px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.premium-package {
    width: 50%;
    background-color: #1691de;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

.warning-message {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.packages .time {
    display: flex;
    align-items: center;
    justify-content: center;
}

.packages .time .time-icon {
    margin: 10px;
}

.premium-package ul, .package ul {
    padding: 0;
}

.package .price, .premium-package .price {
    font-size: 60px;
}

.premium-packages {
    display: flex;
    width: 100%;
    justify-content: center;
}

.premium-packages .package {
    width: 100%;
}


#AddOns .options {
    display: flex;
    justify-content: center;
    box-sizing: border-box;
}

#AddOns ul {
    width: 100%;
    display: grid;
    padding-left: 0px;
    grid-template-columns: repeat(4, minmax(0, 1fr));}
#AddOns li {
    display: flex;
    flex-direction: column;
    margin: 10px;
    align-items: center;
    border: 1px solid rgba(255, 255, 255, 0.18);
    padding: 25px;
    justify-content: space-between;
    border-radius: 20px;
}

.paint-correction {
    width: 40%;
    margin: auto;
}

#Schedule #SelectPackage button {
    padding: 10px;
    width: 100%;
}

#SelectPackage .selected {
    background-color: rgba(255, 255, 255, 0.2);
}

#AddOns button {
    width: 80%;
    color: #fefeff;
    margin-top: 20px;
}

#AddOns .selected {
    background-color: rgba(255, 255, 255, 0.2);
    color:#fefeff;
}

.next, .previous {
    display: flex;
    align-items: center;
}

.arrow .next {
    margin-top: 50px;
    display: flex;
    justify-content: flex-end;
}

.arrows .next:hover, .arrows .previous:hover, .arrow .next:hover {
    cursor: pointer;
}

.arrows {
    margin-top: 50px;
    display: flex;
    justify-content: space-between;
}

.premium-package .title {
    display: flex;
    align-items: center;
}

.premium-package .star {
    margin: 10px;
}

.space {
    height: 60px;
    margin-bottom: 50px;
}


@media screen and (max-width:768px) {
    .tracker {
        width: 20px;
        height: 20px;
    }
    #SelectPackage, #AddOns, #ScheduleAppointment {
        margin-top: 30vh;
    }
    .paint-correction {
        width: 74%;
        margin: auto;
    }

    .top-packages {
        display: flex;
        flex-direction: column;
        width: 100vw;
        align-items: center;

    }
    .package {
        width: 50%;
        margin: 20px;
        background-color: #1691de;
        border-radius: 20px;
        padding: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
    }
    .premium-package {
        width: 62%;
        background-color: #1691de;
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 20px;
    }
    #AddOns .options {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    #AddOns ul {
        width: 100%;
        padding-left: 0px;
    }
    #AddOns li {
        display: flex;
        align-items: center;
        border: 1px white solid;
        padding: 20px;
        justify-content: space-between;
    }

    /* #AddOns button {
        padding: 10px;
        width: 50px;
    } */
    #SelectPackage button {
        padding: 20px;
        font-size: 12px;
        width: 60%;
    }
    #AddOns ul {
        width: 100%;
        display: grid;
        padding-left: 0px;
        grid-template-columns: repeat(3, minmax(0, 1fr));}
    #AddOns li {
        display: flex;
        flex-direction: column;
        margin: 10px;
        align-items: center;
        border: 1px solid rgba(255, 255, 255, 0.18);
        padding: 25px;
        justify-content: space-between;
        border-radius: 20px;
    }
}
@media screen and (max-width:425px) {
    #SelectPackage, #AddOns, #ScheduleAppointment {
        margin-top: 35vh;
    }
    #AddOns ul {
        width: 90%;
        display: grid;
        padding-left: 0px;
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }

    #SelectPackage .container, #AddOns .container, #ScheduleAppointment .container {
        flex-direction: column;
        padding-left: 0px;
        padding-right: 0px;
    }

    .estimate-total {
        margin-top: 20px;
        text-align: center;
        width: 100%;
    }
    .tracker {
        height: 20px;
        width: 20px;
    }
    .warning-message {
        text-align: start;
    }
    .package {
        width: 70%;
    }
    .premium-package {
        width: 100%;
        box-sizing: border-box;
    }
}

/* @media screen and (max-width:320px) {
    #iron {
        flex-direction: column;

    }
} */
