#Nav {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    top: 0;
    z-index: 100;
    position: sticky;
    margin-top: -142px;
    align-items: center;
}

.logo img {
    max-height: 300px;
    max-width: 100%;
}

.logo {
    overflow: hidden;
    height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

nav {
    width: 50%;
    display: flex;
    justify-content: space-around;
    visibility: none;
    align-items: center;
}

nav > div {
    width: 100%;
    display: flex;
    justify-content: space-around;
    border: 1px solid rgba(0, 0, 0, 0);
    padding: 10px;
}

nav > div:hover {
    padding: 10px;
    border-radius: 10px;
    background-color: rgba(0, 0, 0, 0.6);
}



.row {
    display: flex;
}



.menu-burger {
    display: none;
}

@media screen and (max-width:768px) {
    .logo img {
        max-height: 200px;
        max-width: 100%;
    }
}

@media screen and (max-width:425px) {
    #Nav {
        margin-top: -290px;
    }
    #Nav nav {
        position:absolute;
    }
    .menu-burger {
        display: block;
    }

    #Nav nav {
        top: -100vh;
        transition: 1s;
    }

    #Nav .responsive-nav {
        transform: translateY(115vh);
        display: flex;
        flex-direction: column;
        width: 100vw;
        overflow: hidden;
        background-color: rgba(0,0,0,.8);
        left: 0px;
        top: -820px;
        /* make mobile menu not have overhang */
        height: 100vh;
        padding-top: 20vh;
        margin-top: -100px;
    }

    #Nav .responsive-nav > div {
        font-size: 24px;
        margin: 10px 0px 10px 0px;
    }

    #Nav .row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100vw;
        z-index: 100;
    }
}
