#Portfolio {
    display: flex;
    height: 100vh;
    margin-bottom: 90px;
}

#Portfolio .text {
    width: 40%;
    text-align: start;
    padding: 30px;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
}

#Portfolio h1 {
    margin-bottom: 46px;
}

#Portfolio .title {
    width: 80%;
}

.ImageSlider {
    width: 60%;
}

#Portfolio button {
    margin: 0px;
}

#Portfolio .View-all-btn {
    margin-top: 50px;
}

@media screen and (max-width:768px) {
    #Portfolio .text {
        width: 100%;
        text-align: start;
        padding: 30px;
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: center;
    }
    #Portfolio {
        display: flex;
        flex-direction: column;
        margin-bottom: 100px;
    }

    #Portfolio .ImageSlider {
        width: 100%;
        height: 100%;
        margin-top:-190px;
    }
}

@media screen and (max-width:375px) {
    #Portfolio .description {
        width: 90%;
    }
    #Portfolio .View-all-btn {
        margin-top: 30px;
        margin-bottom: 20px;
    }
}

@media screen and (max-width:320px) {
    #Portfolio .text {
        padding: 20px;
    }
}
