#Footer {
    margin-top: 0px;
    bottom: 0;
    display: flex;
    height: 500px;
    justify-content: space-evenly;
    background-color: #0c0c0d;
    position: static;
    padding: 50px;
    text-align: start;
    align-items: center;
}


#Footer h4 {
    margin: 0px;
}

#Footer .info, #Footer .follow-us, #Footer .menu {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: flex-start;
}

#Footer .details {
    justify-content: space-evenly;
    height: 100%;
    display: flex;
    flex-direction: column;
}

#Footer .info .detail, #Footer .menu .details a {
    display: flex;
    font-size: 12px;
    align-items: center;
    color: #DCDCDC;
}

#Footer .menu {
    width: 12%;
}

#Footer .icon {
    color: #dbf059;
    margin-right: 5px;
}

#Footer hr {
    color: #dbf059;
}

#Footer .links {
    display: flex;

}

#Footer .info .details .detail a {
    color: #DCDCDC;
}

#Footer .links a, #Footer .links a:visited {
    background-color: #dbf059;
    color: #0c0c0d;
    border: #dbf059 1px solid;
    border-radius: 100%;
    padding: 10px;
    display: flex;
    cursor: pointer;
    margin: 5px;
}

#Footer img {
    width: 200px;
    height: 200px;
}

@media screen and (max-width:768px) {
    #Footer img {
        display: none;
    }
}

@media screen and (max-width:633px) {
    #Footer {
        padding: 25px;
    }
}

@media screen and (max-width:475px) {
    #Footer {
        flex-direction: column;
    }
    #Footer > div {
        width: 80% !important;
        /* border: 1px solid white; */
    }
    #Footer .details > div, #Footer .menu a{
        margin: 2px;
    }
}
