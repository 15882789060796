#FullPortfolio {
    height: 100vh;
    width: 100vw;
    display: flex;
    padding-left: 30px;
    margin-top: 10vh;
    overflow-x:hidden;
}

#FullPortfolio .header {
    width: 25%;
    height: 100%;
    text-align: start;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

#FullPortfolio h1 {
    font-size: 80px;
    margin-bottom: 20px;
}

#FullPortfolio .images {
    width: 72.5%;
    overflow-y: auto;
    display:flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: -147px;
}

#FullPortfolio .images img {
    height: 80%;
}
@media screen and (max-width:768px) {
    #FullPortfolio h1 {
        font-size: 48px;
        margin-bottom: 20px;
    }
}

@media screen and (max-width:425px) {
    #FullPortfolio {
        height: 200vh;
        width: 100vw;
        display: flex;
        padding-left: 30px;
        flex-direction: column;
        margin-top: 40vh;
        box-sizing: border-box;
    }
    #FullPortfolio .header {
        width: 100%;
        height: 50%;
        text-align: start;
        display: flex;
        justify-content: start;
        margin-bottom: 20px;
        margin-left: 0px;
    }
    #FullPortfolio button {
        width: 70vw;
    }

    #FullPortfolio .btns {
        text-align: center;
        /* margin-left: -30px; */
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-left: -30px;
    }

    #FullPortfolio .images {
        width: 90%;
        overflow-y: auto;
        justify-content: center;
        margin-top: -147px;
        height: 100%;
        box-sizing: border-box;
    }
}
@media screen and (max-width:375px) {
    #FullPortfolio h1 {
        font-size: 42px;
        margin-bottom: 20px;
    }
}
